import React from "react";
import "./style.css";
import RootLayout from "../../components/rootLayout";
import NeetlyAIDashboard from "../../components/NeetlyAIDashboard";

const DashboardPage = () => {
    return (
        <main className="dashBoardPage">
            <NeetlyAIDashboard />
        </main>
    );
};

export default DashboardPage;
