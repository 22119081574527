// ErrorNotification.js

import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ErrorNotification = ({ message, duration = 4000 }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true); // Show the notification

      // Hide the notification after the specified duration
      const timer = setTimeout(() => {
        setVisible(false);
      }, duration);

      // Clean up the timer if the component is unmounted or message changes
      return () => clearTimeout(timer);
    }
  }, [message, duration]);

  if (!visible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 20,
        right: 20,
        backgroundColor: 'white', // White background
        color: 'black', // Black text
        padding: '10px 20px',
        borderRadius: '8px',
        border: '1px solid red', // Red border
        boxShadow: '0px 0px 10px rgba(255, 0, 0, 0.4)', // Darker red shadow
        transition: 'opacity 0.5s ease-in-out',
        zIndex: 9999,
      }}
    >
      <Typography>{message}</Typography>
    </Box>
  );
};

ErrorNotification.propTypes = {
  message: PropTypes.string.isRequired,
  duration: PropTypes.number,
};

export default ErrorNotification;
