// import React from "react";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import Divider from "@mui/material/Divider";
// import PropTypes from "prop-types"; 

// const MCQTypeSelectorDialog = ({ open, onClose, onSelect, topic }) => (
//     <Dialog
//         open={open}
//         onClose={onClose}
//         PaperProps={{
//             sx: {
//                 borderRadius: "16px",
//                 boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
//                 minWidth: "300px",
//             },
//         }}
//     >
//         <DialogTitle sx={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>
//         Select MCQ Type for the chosen topic: {topic}
//                 </DialogTitle>
//         <DialogContent>
//             <List sx={{ p: 0 }}>
//                 {["previous year question","enhanced question(AI generated)","Case-based", "Diagnosis-based", "Management-based"].map((type, index) => (
//                     <React.Fragment key={type}>
//                         <ListItem
//                             Button
//                             onClick={() => onSelect(type)}
//                             sx={{
//                                 p: 2,
//                                 textAlign: "center",
//                                 "&:hover": {
//                                     backgroundColor: "#f0f0f0",
//                                 },
//                             }}
//                         >
//                             <ListItemText primary={type} primaryTypographyProps={{ fontSize: "16px", fontWeight: "medium" }} />
//                         </ListItem>
//                         {index < 3 && <Divider />} {/* Divider between items */}
//                     </React.Fragment>
//                 ))}
//             </List>
//         </DialogContent>
//     </Dialog>
// );

// // Prop type validation
// MCQTypeSelectorDialog.propTypes = {
//     open: PropTypes.bool.isRequired,
//     onClose: PropTypes.func.isRequired,
//     onSelect: PropTypes.func.isRequired,
//     topic: PropTypes.string,
// };

// export default MCQTypeSelectorDialog;


import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const MCQTypeSelectorDialog = ({ open, onClose, onSelect, topic }) => (
    <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
            sx: {
                borderRadius: "16px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                minWidth: "300px",
            },
        }}
    >
        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}>
            Select MCQ Type for the chosen topic: {topic}
        </DialogTitle>
        <DialogContent>
            {/* Previous Year MCQ Section */}
            <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1, textAlign: "center" }}>
                Previous Year Questions
            </Typography>
            <List sx={{ p: 0 }}>
                <ListItem
                    button
                    onClick={() => onSelect("Previous year question")}
                    sx={{
                        p: 2,
                        textAlign: "center",
                        "&:hover": {
                            backgroundColor: "#f0f0f0",
                        },
                    }}
                >
                    <ListItemText
                        primary="Previous-question"
                        primaryTypographyProps={{ fontSize: "16px", fontWeight: "medium" }}
                    />
                </ListItem>
            </List>

            <Divider sx={{ my: 2 }} />

            {/* Neetly Powered Questions Section */}
            <Typography sx={{ fontWeight: "bold", mt: 2, mb: 1, textAlign: "center" }}>
                Neetly Powered Questions
            </Typography>
            <List sx={{ p: 0 }}>
                {["Enhanced questions", "Case-based", "Diagnosis-based", "Management-based"].map((type, index) => (
                    <React.Fragment key={type}>
                        <ListItem
                            button
                            onClick={() => onSelect(type)}
                            sx={{
                                p: 2,
                                textAlign: "center",
                                "&:hover": {
                                    backgroundColor: "#f0f0f0",
                                },
                            }}
                        >
                            <ListItemText
                                primary={type}
                                primaryTypographyProps={{ fontSize: "16px", fontWeight: "medium" }}
                            />
                        </ListItem>
                        {index < 3 && <Divider />} {/* Divider between items */}
                    </React.Fragment>
                ))}
            </List>
        </DialogContent>
    </Dialog>
);

// Prop type validation
MCQTypeSelectorDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    topic: PropTypes.string,
};

export default MCQTypeSelectorDialog;
