import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ThumbUp from '@mui/icons-material/ThumbUp';
import ThumbDown from '@mui/icons-material/ThumbDown';
import FlagIcon from '@mui/icons-material/Flag';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from '@mui/material';
import { useAuth } from '@clerk/clerk-react'; 
import SuccessNotifcation from '../../components/SuccessNotification';
// Assuming Clerk for auth, adjust as needed

const MCQActions = ({ setId, mcqId }) => {
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [sucessNotification,setSuccessNotification] = useState('');
  const { getToken } = useAuth(); // For auth token, adjust as needed

  // API request function
  const apiRequest = async (action, reportMessage = '') => {
    const token = await getToken();
    const response = await fetch('https://neetlybackend.vercel.app/api/messages/action', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ uniqueId: mcqId, action, reportMessage,setId }),
    });

    if (!response.ok) {
      throw new Error(`Failed to ${action}: ${response.statusText}`);
    }
    const data = await response.json();
  };

  // Event handlers
  const handleLike = async () => {
    try {
      await apiRequest('like');
      setSuccessNotification("Thank you for liking the Question")
    } catch (error) {
      console.error('Error liking MCQ:', error.message);
    }
  };

  const handleDislike = async () => {
    try {
      await apiRequest('dislike');
      setSuccessNotification("Your feedback has been saved SuccessFully")

    } catch (error) {
      console.error('Error disliking MCQ:', error.message);
    }
  };

  const handleReport = async () => {
    try {
      await apiRequest('report', reportReason);
      setReportDialogOpen(false);
      setSuccessNotification("Your feedback has been saved SuccessFully")
      setReportReason(''); // Clear reason after submitting

    } catch (error) {
      console.error('Error reporting MCQ:', error.message);
    }
  };

  // Open the report dialog
  const openReportDialog = () => {
    setReportDialogOpen(true);
  };

  return (
    <div>
      <IconButton
        aria-label="Like"
        onClick={handleLike}
      >
        <ThumbUp />
      </IconButton>
      <IconButton
        aria-label="Dislike"
        onClick={handleDislike}
      >
        <ThumbDown />
      </IconButton>
      <IconButton
        color="secondary"
        aria-label="Report"
        onClick={openReportDialog}
      >
        <FlagIcon />
      </IconButton>

      {/* Report Dialog */}
      <Dialog
        open={reportDialogOpen}
        onClose={() => setReportDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Report MCQ</DialogTitle>
        <DialogContent>
          <TextField
            label="Reason for reporting"
            value={reportReason}
            onChange={(e) => setReportReason(e.target.value)}
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReportDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleReport} color="primary">
            Submit Report
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessNotifcation message={sucessNotification} duration={5000} />
    </div>
  );
};

export default MCQActions;
