import React, { createContext, useContext, useState } from 'react';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [localMessages, setLocalMessages] = useState([]);
  
  const addNewMessage = (message) => setLocalMessages((prev) => [...prev, message]);
  const clearMessages = () => setLocalMessages([]);

  return (
    <ChatContext.Provider value={{ selectedChatId, setSelectedChatId, localMessages, addNewMessage, clearMessages }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
