import { SignIn } from "@clerk/clerk-react";
import "./signInPage.css";

const SignInPage = () => {
    return (
        <div className="signInPage">
            <SignIn path="/sign-in" signUpUrl="/sign-up" forceRedirectUrl="/explore" />
        </div>
    );
};

export default SignInPage;
