import React from "react";
import "./style.css";
import ChatLayout from "../../components/chatLayout";
import { SignedIn, UserButton } from "@clerk/clerk-react";
import { SignedOut, SignInButton } from "@clerk/clerk-react";
import { Link } from "react-router-dom";

const ChatPage = () => {
    return (
        <div className="rootLayout">
            <header className="rootLayoutheader">
                <Link to="/" className="logo">
                    <HospitalIcon className="icon" />
                    <span>Neetly AI</span>
                </Link>
                <SignedOut>
                    <SignInButton />
                </SignedOut>
                <div className="user">
                    <SignedIn>
                        <UserButton />
                    </SignedIn>
                </div>
            </header>
            <main>
                <ChatLayout />
            </main>
        </div>
    );
};

function HospitalIcon(props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M12 7v10M9 12h6" />
            <path d="M5 22V2h14v20Z" />
        </svg>
    );
}

export default ChatPage;
