import axios from "axios";
//ntg changed
const API_URL = "https://neetlybackend.vercel.app/api/mcq/generate";

let questionSetCounter = 0;

const dummyQuestionSets = [
    [
        {
            id: 1,
            subject: "pathology",
            text: "Which of the following is a characteristic feature of Cushing's syndrome?",
            type: "single",
            options: ["Hyperpigmentation", "Moon face", "Vitiligo", "Alopecia"],
            correct: ["Moon face"],
            explanation:
                " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
        },
        {
            id: 2,
            subject: "pathology",
            text: "Which of the following are potential complications of untreated hypertension?",
            type: "multiple",
            options: ["Stroke", "Myocardial infarction", "Renal failure", "Osteoporosis"],
            correct: ["Stroke", "Myocardial infarction", "Renal failure"],
            explanation:
                "Untreated hypertension can lead to stroke, myocardial infarction (heart attack), and renal failure. Osteoporosis is not directly related to hypertension.",
        },
    ],
    [
        {
            id: 3,
            subject: "pathology",
            text: "Which of the following are classic symptoms of diabetic ketoacidosis (DKA)?",
            type: "single",
            topic: "pathology",
            options: ["Polyuria", "Polydipsia", "Polyphagia", "Hypertension"],
            correct: ["Polyuria", "Polydipsia", "Polyphagia"],
            explanation:
                "The classic triad of symptoms in DKA includes polyuria (excessive urination), polydipsia (excessive thirst), and polyphagia (excessive hunger). Hypertension is not a classic symptom of DKA.",
        },
        {
            id: 4,
            text: "Which of the following is the most common cause of community-acquired pneumonia?",
            type: "multiple",
            subject: "pathology",
            options: [
                "Streptococcus pneumoniae",
                "Mycoplasma pneumoniae",
                "Haemophilus influenzae",
                "Legionella pneumophila",
            ],
            correct: ["Streptococcus pneumoniae"],
            explanation:
                "Streptococcus pneumoniae (pneumococcus) is the most common cause of community-acquired pneumonia in adults.",
        },
    ],
];

const authService = {
    signup: async (firstName, lastName, email, password) => {
        // Simulating API call
        return new Promise((resolve) => {
            setTimeout(() => {
                const dummyToken = "dummy-jwt-token";
                localStorage.setItem("token", dummyToken);
                resolve({
                    token: dummyToken,
                    user: { firstName, lastName, email },
                });
            }, 500); // Simulate network delay
        });
    },

    login: async (email, password) => {
        // Implement login logic
        return true;
    },
    logout: async () => {
        // Implement logout logic
    },
    // // Simulating API call with dummy data
    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     const questionSet = dummyQuestionSets[questionSetCounter % dummyQuestionSets.length];
    //     questionSetCounter++;
    //     resolve(questionSet);
    //   }, 1000); // Simulate network delay
    // })
    // Uncomment the following block when you're ready to use real API calls
    getQuestions: async (topic,textId=null,token,mcqType) => {
        const headers = {
            accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,

        };
        const data = {
            topic: topic,
            textId:textId,
            type:mcqType,
            numberOfQuestions: 0,
        };
       
        try {
            const response = await axios.post(`${API_URL}`, data, { headers });
            console.log("data is received", response.data);
            const arrayList = [...response.data];
            return arrayList;
        } catch (error) {
            throw error.response?.data?.message || "An error occurred while fetching questions.";
        }
    },

    //    json2array(json){
    //     var result = [];
    //     var keys = Object.keys(json);
    //     keys.forEach(function(key){
    //         result.push(json[key]);
    //     });
    //     return result;
    // }
};

export default authService;