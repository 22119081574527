import "./style.css";
import React, { useState, useEffect } from "react";

import NewPrompt from "../newPrompt";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import Markdown from "react-markdown";
import { IKImage } from "imagekitio-react";
import { useAuth } from "@clerk/clerk-react";
const API_URL = "https://neetlybackend.vercel.app";
const VITE_IMAGE_KIT_ENDPOINT = "https://ik.imagekit.io/x54uzdlwy";

const ChatConversation = () => {
    const path = useLocation().pathname;
    const chatId = path.split("/").pop();
    // const [data, setData] = useState(null);
    // const [isPending, setIsPending] = useState(true);
    // const [error, setError] = useState(null);
    const { getToken } = useAuth();
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const token = await getToken();
    //             const response = await fetch(`${API_URL}/api/chats/${chatId}`, {
    //                 method: "GET",
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                     mode: "cors",
    //                 },
    //             });
    //             if (!response.ok) {
    //                 throw new Error("Failed to fetch chat");
    //             }
    //             const data = await response.json();
    //             setData(data);
    //             setIsPending(false);
    //         } catch (error) {
    //             setError(error.message);
    //             setIsPending(false);
    //         }
    //     };

    //     fetchData();
    // }, [getToken, chatId]);
    const { isPending, error, data } = useQuery({
        queryKey: ["chat", chatId],
        queryFn: async () => {
            const token = await getToken(); // Assuming getToken is defined and returns a token
            const response = await fetch(`${API_URL}/api/chats/${chatId}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    mode: "cors",
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch chat");
            }

            return response.json();
        },
        enabled: !!chatId, // This ensures the query only runs if chatId is truthy
    });
    // const { isPending, error, data } = useQuery({
    //     queryKey: ["chat", chatId],
    //     queryFn: () =>
    //         fetch(`${API_URL}/api/chats/${chatId}`, {
    //             credentials: "include",
    //         }).then((res) => res.json()),
    // });

    return (
        <div className="chatPage">
            <div className="wrapper">
                <div className="chat">
                    {isPending
                        ? "Loading..."
                        : error
                        ? "Something went wrong!"
                        : data?.history?.map((message, i) => (
                              <>
                                  {message.img && (
                                      <IKImage
                                          urlEndpoint={VITE_IMAGE_KIT_ENDPOINT}
                                          path={message.img}
                                          height="300"
                                          width="400"
                                          transformation={[{ height: 300, width: 400 }]}
                                          loading="lazy"
                                          lqip={{ active: true, quality: 20 }}
                                      />
                                  )}
                                  <div className={message.role === "user" ? "message user" : "message"} key={i}>
                                      <Markdown>{message.parts[0].text}</Markdown>
                                  </div>
                              </>
                          ))}

                    {data && <NewPrompt data={data} />}
                </div>
            </div>
        </div>
    );
};

export default ChatConversation;
