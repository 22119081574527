import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Helmet } from "react-helmet";
import { Hospital, Stethoscope, Award, HeartPulse, Microscope, Trophy } from "lucide-react";
import "./Component.css";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import "./HomePage.css";

export default function HomePage() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const toggleNav = () => setIsNavOpen(!isNavOpen);

  return (
    <div className="home-page">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Neetly AI - Elevating Medical Education</title>
        <meta
          name="description"
          content="Neetly AI provides high-quality, evidence-based MCQs for medical students and professionals to excel in exams."
        />
        <meta name="keywords" content="medical MCQs, USMLE, NEET, medical education, AI-powered MCQs" />
        <link rel="canonical" href="https://neetlyai.com/" />
      </Helmet>

      {/* Header / AppBar */}
      <AppBar position="sticky" color="default">
        <Toolbar className="toolbar">
          <Link to="/" className="brand-link" aria-label="Neetly AI Home">
            <Hospital className="icon" />
            <Typography variant="h4" className="brand-name">
              Neetly AI
            </Typography>
          </Link>
          {isMobile ? (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={toggleNav}
              className="menu-icon"
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div className="nav-links">
              <Button color="inherit" component={Link} to="/explore">
                Explore MCQs
              </Button>
              <Button color="inherit" component={Link} to="/dashboard">
                Ask Questions
              </Button>
              <Button color="inherit" component={Link} to="/contact">
                Contact Us
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Navigation */}
      {isMobile && (
        <Drawer
          anchor="top"
          open={isNavOpen}
          onClose={toggleNav}
          className="drawer"
        >
          <List>
            <ListItem button component={Link} to="/explore" onClick={toggleNav}>
              <ListItemText primary="Explore MCQs" />
            </ListItem>
            <ListItem button component={Link} to="/dashboard" onClick={toggleNav}>
              <ListItemText primary="Ask Questions" />
            </ListItem>
            <ListItem button component={Link} to="/contact" onClick={toggleNav}>
              <ListItemText primary="Contact Us" />
            </ListItem>
          </List>
        </Drawer>
      )}

      {/* Main Content */}
      <main>
        <section className="hero-section">
          <Container maxWidth="md" className="hero-container">
            <Box textAlign="center" sx={{ px: { xs: 2, md: 0 }, py: { xs: 4, md: 8 } }}>
              <div className="badge">Medical MCQ Experts</div>
              <Typography variant={isMobile ? "h4" : "h2"} className="hero-title">
                Neetly AI - Elevating Medical Education
              </Typography>
              <Typography variant="body1" className="hero-description">
                Providing high-quality, evidence-based multiple choice questions to help medical students and professionals excel.
              </Typography>
             <Box
  className="hero-buttons"
  sx={{
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "center",  // Aligns buttons to the center horizontally
    gap: 2,
    mt: 2,
  }}
>
  <Button
    variant="contained"
    color="success"
    component={Link}
    to="/explore"
    aria-label="Explore MCQs"
  >
    Ask MCQs
  </Button>
  <Button
    variant="contained"
    color="success"
    component={Link}
    to="/dashboard"
    aria-label="Ask Case Study"
  >
    Ask Case Study
  </Button>
</Box>
</Box>
          </Container>
        </section>

        <section className="featured-section">
          <Container maxWidth="lg">
            <Box textAlign="center" mb={4}>
              <div className="badge">Featured Products</div>
              <Typography variant={isMobile ? "h4" : "h3"} className="section-title">
                Comprehensive Medical MCQ Solutions
              </Typography>
              <Typography variant="body1" className="section-description">
                Explore our diverse range of high-quality medical MCQ products designed to enhance your learning and preparation.
              </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" justifyContent="center" gap={4}>
              {[
                { icon: <Stethoscope />, title: "USMLE Step 1 MCQs", description: "Comprehensive bank of high-yield MCQs to prepare for the USMLE Step 1 exam." },
                { icon: <HeartPulse />, title: "Cardiology MCQs", description: "In-depth questions covering all aspects of cardiovascular medicine." },
                { icon: <Microscope />, title: "Pathology Review", description: "Detailed MCQs to master the fundamentals of pathology." },
                { icon: <Award />, title: "Board Exam Prep", description: "Targeted questions for various medical board certifications." },
                { icon: <Trophy />, title: "Clinical Case Studies", description: "Real-world scenarios to apply your medical knowledge." },
              ].map((product, index) => (
                <Box key={index} className="product-card" sx={{ width: { xs: "100%", sm: "45%", md: "30%" } }} aria-label={`${product.title}`}>
                  <Box className="product-icon">{product.icon}</Box>
                  <Typography variant="h5" className="product-title">
                    {product.title}
                  </Typography>
                  <Typography variant="body2" className="product-description">
                    {product.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Container>
        </section>
      </main>

      {/* Footer */}
      <footer className="footer">
        <Container className="footer-container">
          <Typography variant="body2" className="footer-text">
            © 2024 Neetly AI. All rights reserved.
          </Typography>
          <Box className="footer-links">
            <Link to="/terms" className="footer-link">
              Terms of Service
            </Link>
            <Link to="/privacy" className="footer-link">
              Privacy Policy
            </Link>
          </Box>
        </Container>
      </footer>
    </div>
  );
}
