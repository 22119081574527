import "./style.css";
import ChatList from "../chatList";
import ChatConversation from "../chatConversation";

const ChatLayout = () => {
    return (
        <div className="dashboardLayout">
            <div className="menu">
                <ChatList />
            </div>
            <div className="content">
                {/* <ChatWindow /> */}
                <ChatConversation />

                {/* <div className="chatDashBoardPage">
                    <div className="formContainer">
                        <form>
                            <input type="text" name="text" placeholder="Ask me anything..." />
                            <button>
                                <img src="/arrow.png" alt="" />
                            </button>
                        </form>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default ChatLayout;
