// import React from "react";
// import { Box, Typography, Skeleton, Divider } from "@mui/material";

// const ActivityFeed = ({ activityFeed, activityLoading }) => {
//   const getSuggestions = (activities) => {
//     let suggestions = [];
//     activities.forEach((activity) => {
//       if (activity.activity.includes("Completed")) {
//         suggestions.push("Consider reviewing questions you got wrong to strengthen weak areas.");
//       }
//       if (activity.activity.includes("Reattempted")) {
//         suggestions.push("Reattempt success rate looks promising. Keep focusing on challenging topics.");
//       }
//       if (activity.activity.includes("New Set Started")) {
//         suggestions.push("Good job starting a new set! Try to complete it within this week for better retention.");
//       }
//     });
//     return suggestions;
//   };

//   return (
//     <>
    
//       {activityLoading ? (
//         Array.from(new Array(3)).map((_, index) => (
//           <Box key={index} mb={2}>
//             <Skeleton variant="rectangular" height={80} />
//           </Box>
//         ))
//       ) : (
//         <Box>
//           {Object.keys(activityFeed).length === 0 ? (
//             <Box mb={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
//               <Typography variant="body1" color="textSecondary">
//                 No recent activity available. Start practicing to see your progress!
//               </Typography>
//             </Box>
//           ) : (
//             Object.keys(activityFeed).map((date, idx) => (
//               <Box key={date} mb={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
//                 <Typography variant="body1" style={{ fontWeight: 600 }}>
//                   {date}
//                 </Typography>
//                 {activityFeed[date].map((activity, actIdx) => (
//                   <Box key={`${date}-${actIdx}`}>
//                     <Typography variant="body2" color="textSecondary">
//                       - {activity.activity}
//                     </Typography>
//                   </Box>
//                 ))}

//                 {/* Adding Relevant Suggestions for User */}
//                 <Box mt={2}>
//                   {getSuggestions(activityFeed[date]).map((suggestion, suggestionIdx) => (
//                     <Box key={`${date}-suggestion-${suggestionIdx}`} mt={1} p={1} bgcolor="#e8f5e9" borderRadius={4}>
//                       <Typography variant="body2" color="textPrimary">
//                         Suggestion: {suggestion}
//                       </Typography>
//                     </Box>
//                   ))}
//                 </Box>

//                 <Divider sx={{ my: 2 }} />
//               </Box>
//             ))
//           )}

//           {/* Summary Section */}
//           <Box mb={2} p={2} bgcolor="#e3f2fd" borderRadius={4}>
//             <Typography variant="h6" color="primary">
//               Weekly Progress Summary
//             </Typography>
//             <Typography variant="body2" color="textSecondary">
//               Total Questions Solved: {Object.keys(activityFeed).reduce((acc, date) => {
//                 return (
//                   acc +
//                   activityFeed[date].reduce((sum, activity) => {
//                     return activity.activity.includes("Completed") ? sum + 10 : sum; // Assuming each set has 10 questions
//                   }, 0)
//                 );
//               }, 0)}
//             </Typography>
//             <Typography variant="body2" color="textSecondary">
//               Completed Sets: {Object.keys(activityFeed).reduce((acc, date) => {
//                 return (
//                   acc +
//                   activityFeed[date].filter((activity) => activity.activity.includes("Completed")).length
//                 );
//               }, 0)}
//             </Typography>
//             <Typography variant="body2" color="textSecondary">
//               Success Rate Improvement: Track your weak areas for consistent improvement!
//             </Typography>
//           </Box>
//         </Box>
//       )}
//     </>
//   );
// };

// export default ActivityFeed;

import React, { useEffect, useState } from "react";
import { Box, Typography, Skeleton, Divider } from "@mui/material";
import axios from "axios";
import { useAuth } from "@clerk/clerk-react";

const ActivityFeed = () => {
  const [activityFeed, setActivityFeed] = useState([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const { getToken } = useAuth();

  useEffect(() => {
    // Fetch activity data from the API
    const fetchActivityFeed = async () => {
      try {
        setActivityLoading(true);
        
        const token = await getToken();

        const headers = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get("https://neetlybackend.vercel.app/api/activity-feed", headers);
        const data = response.data;
        setActivityFeed(data.activityFeed);

      } catch (error) {
        console.error("Error fetching activity feed:", error);
      } finally {
        setActivityLoading(false);
      }
    };

    fetchActivityFeed();
  }, []);

  // const getSuggestions = (weakTopics, improvementSuggestions) => {
  //   return weakTopics.map((topic, idx) => (
  //     <Box key={idx} mt={1} p={1} bgcolor="#e8f5e9" borderRadius={4}>
  //       <Typography variant="body2" color="textPrimary">
  //         Improvement for {topic}:
  //       </Typography>
  //       {improvementSuggestions[topic] ? (
  //         improvementSuggestions[topic].map((suggestion, suggestionIdx) => (
  //           <Typography key={suggestionIdx} variant="body2" color="textSecondary">
  //             - {suggestion}
  //           </Typography>
  //         ))
  //       ) : (
  //         <Typography variant="body2" color="textSecondary">
  //           - No specific suggestions available.
  //         </Typography>
  //       )}
  //     </Box>
  //   ));
  // };
  const getSuggestions = (weakTopics, improvementSuggestions) => {
    if (!Array.isArray(weakTopics) ) {
      console.error("Invalid data structure for weakTopics or improvementSuggestions");
      return null;
    }
    console.log(improvementSuggestions);
    if(typeof improvementSuggestions !== 'object'){
      console.error("Invalid data structure or improvementSuggestions");
      return null;
    }
  
    return weakTopics
      .filter((topic) => topic && topic !== "not define") // Ignore "not define" topics
      .map((topic, idx) => {
        const suggestions = improvementSuggestions[topic] || []; // Default to empty array if undefined
  
        return (
          <Box key={idx} mt={1} p={1} bgcolor="#e8f5e9" borderRadius={4}>
            <Typography variant="body2" color="textPrimary">
              Improvement for {topic}:
            </Typography>
            {Array.isArray(suggestions) && suggestions.length > 0 ? (
              suggestions.map((suggestion, suggestionIdx) => (
                <Typography key={suggestionIdx} variant="body2" color="textSecondary">
                  - {suggestion}
                </Typography>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary">
                - No specific suggestions available for this topic.
              </Typography>
            )}
          </Box>
        );
      });
  };  
  
  return (
    <>
      {activityLoading ? (
        Array.from(new Array(3)).map((_, index) => (
          <Box key={index} mb={2}>
            <Skeleton variant="rectangular" height={80} />
          </Box>
        ))
      ) : (
        <Box>
          {activityFeed.length === 0 ? (
            <Box mb={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
              <Typography variant="body1" color="textSecondary">
                No recent activity available. Start practicing to see your progress!
              </Typography>
            </Box>
          ) : (
            activityFeed.map((weekData, idx) => (
              <Box key={idx} mb={2} p={2} bgcolor="#f0f0f0" borderRadius={4}>
                <Typography variant="body1" style={{ fontWeight: 600 }}>
                  {weekData.week}
                </Typography>

                {/* Display Weekly Metrics */}
                <Box mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    Accuracy: {weekData.metrics.accuracy.toFixed(2)}%
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Total Questions: {weekData.metrics.totalQuestions}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Correct Answers: {weekData.metrics.correctAnswers}
                  </Typography>
                </Box>

                {/* Display AI-Generated Strong and Weak Topics */}
                <Box mt={2}>
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    Strong Topics:
                  </Typography>
                  {weekData.metrics.strongTopics.map((topic, topicIdx) => (
                    <Typography key={topicIdx} variant="body2" color="textSecondary">
                      - {topic}
                    </Typography>
                  ))}
                </Box>

                <Box mt={2}>
                  <Typography variant="body2" style={{ fontWeight: 600 }}>
                    Weak Topics:
                  </Typography>
                  {weekData.metrics.weakTopics.map((topic, topicIdx) => (
                    <Typography key={topicIdx} variant="body2" color="textSecondary">
                      - {topic}
                    </Typography>
                  ))}
                </Box>

                {/* Adding Specific Improvement Suggestions */}
                <Box mt={2}>
                  <Typography variant="h6" color="primary">
                    Improvement Suggestions
                  </Typography>
                  {getSuggestions(weekData.metrics.weakTopics, weekData.improvementSuggestions)}
                </Box>

                <Divider sx={{ my: 2 }} />
              </Box>
            ))
          )}

          {/* Summary Section */}
          <Box mb={2} p={2} bgcolor="#e3f2fd" borderRadius={4}>
            <Typography variant="h6" color="primary">
              Weekly Progress Summary
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Total Questions Solved: {activityFeed.reduce((acc, week) => acc + week.metrics.totalQuestions, 0)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Correct Answers: {activityFeed.reduce((acc, week) => acc + week.metrics.correctAnswers, 0)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Success Rate Improvement: Track your weak areas for consistent improvement!
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ActivityFeed;
